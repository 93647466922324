@import "https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap";

* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

section {
  background: #fff;
  width: 100%;
}

img {
  width: 50px;
  height: 50px;
}

section .container {
  position: relative;
  padding: 0 5%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 10vh;
}

section .container header {
  background-color: #0a67f2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 5%;
}

section .container header .logo {
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  font-size: 1.8rem;
  text-decoration: none;
  margin-right: auto;
  margin-left: 0px;
}

.insignia {
  width: 100px;
  height: 100px;
  cursor: pointer;
}

section .container header ul {
  position: relative;
  display: flex;
}

section .container header ul li {
  list-style: none;
  margin: 0 1rem;
}

section .container header ul li a {
  cursor: pointer;
  position: relative;
  text-decoration: none;
  font-size: 1rem;
  padding: 8px 16px;
  color: #fff;
  transition: background 0.3s, color 0.3s;
}

section .container header ul li a:hover {
  background: #fff;
  color: #111111;
}

section .container header ul li a.active {
  background: #fff;
  color: #111;
}

section .container header .menuIcon {
  display: none;
}

@media (max-width: 991px) {
  section .container {
    padding: 10px;
  }

  section .container header {
    padding: 10px 20px;
  }

  section .container header ul {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #fff;
    z-index: 1000;
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  section .container header ul li {
    text-align: center;
    margin: 10px;
  }

  section .container header ul li a {
    color: #333;
    font-size: 1.5em;
  }

  section .container header ul li a:hover {
    color: #f00;
  }

  section .container header .menuIcon {
    position: fixed;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    display: initial;
    z-index: 10000;
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }

  section .container header .menuIcon.active {
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: center;
  }

  section .container header.active ul {
    display: flex;
  }
}
