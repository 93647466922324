/* ScannerComponent.module.css */

/* Contenedor principal */
.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f4f4f4;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  z-index: 1; /* Asegura que el contenedor no sobreponga elementos con mayor z-index */
  position: relative; /* Mantiene el contexto del z-index */
}

/* Estilo del encabezado */
.header {
  font-size: 2.5rem;
  text-align: center;
  color: #333;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.1rem;
}

/* Contenedor principal del contenido */
.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 700px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 2rem;
  position: relative; /* Evita que el contenido sobreponga otros elementos */
}

/* Contenedor centrado para el botón de selección de curso y el lector QR */
.centeredContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Estilo del contenedor de la lista de cursos */
.listContainer {
  width: 100%;
  margin-bottom: 2rem;
}

/* Estilo del contenedor del scanner */
.scannerContainer {
  width: 100%;
}

/* Estilo de los botones y otros elementos interactivos */
.button {
  background-color: #007bff;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin-bottom: 1rem; /* Añade un margen inferior para espaciar los elementos */
}

.button:hover {
  background-color: #0056b3;
}

/* Estilo de los campos de entrada */
.input, .select {
  width: 100%;
  padding: 0.75rem;
  margin: 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  box-sizing: border-box;
}

.input:focus, .select:focus {
  border-color: #007bff;
  outline: none;
}
