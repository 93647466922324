.qr-reader {
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}



.start-button-container {
  text-align: center;
  margin-top: 5px;
}

.start-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
}

.stop-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #d0000a;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 5px;
}

.start-button:hover {
  background-color: #0056b3;
}

@media (max-width: 426px) {
  .qr-reader {
    padding: 20px;
  }

  .qr-box {
    width: 100%;
    height: auto;
  }

  .start-button {
    width: 100%;
    padding: 15px;
    font-size: 18px;
   
  }
}
