.custom-select {
    position: relative;
    width: 400px;
    margin: 10px;
    
  }
  
  .selected-option {
    padding: 10px 20px;
    font-size: 14px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .selected-option:hover {
    background-color: #0056b3;
  }
  
  .options {
    position: absolute;
    width: 100%;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 1;
    max-height: 250px;
    overflow-y: auto;
  }
  
  .option {
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .option:hover {
    background-color: #007bff;
    color: white;
  }
  