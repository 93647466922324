.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}

.formWrapper {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px; /* Ajusta el ancho máximo del formulario */
  padding: 20px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.inputField {
  margin-bottom: 15px;
}

.inputField label {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
  color: #555;
}

.inputField input,
.inputField select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
}

.inputField input:focus,
.inputField select:focus {
  border-color: #007bff;
  outline: none;
}

.btn {
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
}

.btnCancelar {
  background: #e40505;
  margin: 1px;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
}

.btnCancelar:hover{
  background: hsl(351, 100%, 35%);
}

.btn:hover {
  background: #0056b3;
}

.btn:focus {
  outline: none;
}

